import React from 'react';
import styled from 'styled-components';
const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.46rem 0 0.4rem;
  > h2 {
    color: #ff7c34;
    text-transform: uppercase;
    font-size: 0.36rem;
    margin-bottom: 0.26rem;
  }
  > h3 {
    color: #2c2c2c;
    font-size: 0.22rem;
  }
`;
export default function Banner({ title, subTitle, img, imgW, text = '' }) {
  return (
    <Wrapper>
      <h2 data-aos="fade-right">{title}</h2>
      <h3 data-aos="fade-left">{subTitle}</h3>
      {(title == 'slogan' || title == 'value') && <h3 data-aos="fade-left">{text}</h3>}
      <img
        data-aos="fade-up"
        src={img}
        alt="Banner"
        style={{ width: imgW, marginTop: '0.44rem' }}
      />
    </Wrapper>
  );
}
